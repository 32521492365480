<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h3 class="mb-0">{{ $t('Common.food_modifiers') }}</h3>
      </v-card-title>
      <v-divider class="mx-4 my-0"></v-divider>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-row align="center" class="px-6">
            <v-col
              cols="12"
              class="py-0 d-flex align-center"
              style="height:70px"
            >
              <v-chip color="info" to="/product/modifier/new" class="mr-3">
                <span class="white--text mb-0">
                  <v-icon left>add</v-icon>ADD
                </span>
              </v-chip>
              <v-chip color="success" @click="saveModifiers">
                <span class="white--text mb-0">
                  SAVE MODIFIERS ORDER
                </span>
              </v-chip>
            </v-col>
          </v-row>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-row v-if="modifierList" class="px-6">
            <v-col cols="12" class="py-2">
              <v-row>
                <v-col cols="12" class="my-4">
                  <span class="grey--text">Available Modifiers:</span>
                </v-col>
              </v-row>
              <v-card class="mb-4">
                <v-card-subtitle>
                  <v-text-field
                    v-model="searchText"
                    append-icon="search"
                    label="Search modifiers..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-subtitle>
                <v-data-table
                  :headers="headers"
                  :items="modifierList"
                  :search="searchText"
                  item-key="name"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:body="props">
                    <draggable
                      :disabled="searchText.length > 0"
                      :list="props.items"
                      tag="tbody"
                      :move="moved"
                      @change="changeOrder"
                    >
                      <tr v-for="(modifier, index) in props.items" :key="index">
                        <td>
                          <v-icon small class="handle-icon"
                            >mdi-arrow-all</v-icon
                          >
                        </td>
                        <td>{{ modifier.name }}</td>
                        <td>
                          <v-btn
                            icon
                            text
                            color="error"
                            @click="deleteModifier(modifier.id)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-menu open-on-hover bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="success"
                                v-bind="attrs"
                                v-on="on"
                                icon
                              >
                                <v-icon>mdi-information</v-icon>
                              </v-btn>
                            </template>
                            <v-card class="pa-1" min-width="280">
                              <v-card-subtitle>Options:</v-card-subtitle>
                              <v-list-item
                                v-for="subItem in modifier.options"
                                :key="subItem.name"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    ><small>{{
                                      subItem.name
                                    }}</small></v-list-item-title
                                  >
                                </v-list-item-content>
                                <v-spacer></v-spacer>
                                <small>{{
                                  subItem.available ? '' : 'NA'
                                }}</small>
                                <v-list-item-action>
                                  <small
                                    ><span>{{
                                      subItem.price | currency
                                    }}</span></small
                                  >
                                </v-list-item-action>
                              </v-list-item>
                            </v-card>
                          </v-menu>
                        </td>
                        <td>
                          <v-row justify="center" align="center">
                            <v-col cols="6">
                              <v-btn
                                icon
                                text
                                color="success"
                                :to="'/product/modifier/' + modifier.id"
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="6">
                              <v-checkbox
                                v-model="selectedModifierIDs"
                                :value="modifier.id"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-row
            class="d-flex justify-center align-center px-4"
            style="height:70px"
          >
            <v-col cols="6" class="py-0">
              <v-text-field
                placeholder="Search dish name"
                v-model="searchDish"
                prepend-icon="search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0 d-flex justify-end">
              <v-chip
                color="info"
                @click="onApply"
                :disabled="!this.selectedDishes.length"
                class="mr-3"
              >
                <span class="white--text mb-0">
                  <v-icon left>check</v-icon>APPLY
                </span>
              </v-chip>
            </v-col>
          </v-row>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-row>
            <v-col cols="12" class="py-2">
              <v-row class="d-flex align-center pt-2 pr-8">
                <v-col cols="8" class="px-4 pa-0">
                  <span class="mb-0 grey--text pl-6">Available Products:</span>
                </v-col>
                <v-col cols="4" class="pa-0 d-flex justify-end">
                  <span class="mb-0 pt-1 grey--text">Select All</span>
                  <v-checkbox
                    hide-details
                    v-model="selectAllDish"
                    class="mt-0 mb-1"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0 px-6">
                  <v-card height="600px" class="overflow-y-auto">
                    <v-list>
                      <v-list-item-group v-model="selectedItem">
                        <v-list-item
                          v-for="(item, index) in filteredDishList"
                          :key="index"
                        >
                          <v-list-item-content @click="onClickDish(item)">
                            <v-list-item-title
                              v-text="item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-checkbox
                              hide-details
                              :value="item"
                              v-model="selectedDishes"
                            ></v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Modifier"
      message="Some products may use this modifier. Are you sure you want to delete this Modifier?"
      @onConfirm="onDeleteModifier"
    ></delete-confirmation-dialog>
    <v-snackbar v-model="snackbar" color="success" :timeout="2000">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable
  },
  data() {
    return {
      selectedItem: -1,
      searchText: '',
      selectAllDish: false,
      selectedModifierID: null,
      selectedDishes: [],
      selectedModifierIDs: [],
      searchDish: null,
      tempList: [],
      modifierList: [],
      dishList: [],
      snackbar: false,
      snackbarText: '',
      headers: [
        { text: '', sortable: false, filterable: false },
        { text: 'Name', value: 'name', sortable: false, align: 'start' },
        {
          text: 'Delete',
          value: 'actions',
          sortable: false,
          filterable: false
        },
        { text: 'Options', filterable: false, sortable: false },
        { text: 'Actions', filterable: false, sortable: false }
      ]
    }
  },
  watch: {
    selectAllDish(nv) {
      if (nv) {
        this.selectedDishes = this.filteredDishList
      } else {
        this.selectedDishes = []
      }
    },
    searchDish() {
      this.selectedDishes = []
      this.selectAllDish = false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
    filteredDishList() {
      let list = this.dishList
      if (this.searchDish) {
        list = this.dishList.filter(p =>
          p.name.toLowerCase().includes(this.searchDish.toLowerCase())
        )
      }
      return list
    }
  },
  mounted() {
    this.$store.dispatch('setLoading', true)
    this.getModifierList()
    this.getDishes()
  },
  methods: {
    saveModifiers() {
      const that = this
      this.$store.dispatch('saveModifiers', this.modifierList).then(() => {
        this.snackbarText = 'Saved Modifiers order!'
        this.snackbar = true
        that.getModifierList()
      })
    },
    onClickDish(dish) {
      if (dish.modifiers) {
        this.selectedModifierIDs = Object.keys(dish.modifiers)
      } else {
        this.selectedModifierIDs = []
      }
    },
    onApply() {
      const that = this
      let selectedModifiers = this.modifierList.filter(mod =>
        this.selectedModifierIDs.includes(mod.id)
      )
      let objModifiers = {}
      for (let item of selectedModifiers) {
        objModifiers[item.id] = item
      }
      let updatedDishes = this.selectedDishes.map(dish => ({
        ...dish,
        modifiers: objModifiers
      }))
      this.$store.dispatch('saveMultipleDishes', updatedDishes).then(() => {
        that.snackbarText = 'Applied selected modifiers to selected dishes.'
        that.snackbar = true
        that.getModifierList()
        that.getDishes()
      })
    },
    deleteModifier(item_id) {
      this.selectedModifierID = item_id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteModifier() {
      if (this.selectedModifierID) {
        this.modifierList = this.modifierList.filter(
          m => m.id !== this.selectedModifierID
        )
        this.$store
          .dispatch('deleteModifier', this.selectedModifierID)
          .then(() => {
            this.getModifierList()
            this.$refs.deleteConfirmationDialog.close()
          })
      }
    },
    getModifierList() {
      this.$store.dispatch('loadModifiers').then(res => {
        this.modifierList = res.sort((a, b) => a.no - b.no)
      })
    },
    getDishes() {
      const that = this
      this.$store.dispatch('loadDishList').then(dishes => {
        that.dishList = dishes
        that.selectedDishes = []
        that.selectedModifierIDs = []
        that.selectAllDish = false
        that.selectedItem = -1
      })
    },
    changeOrder() {
      this.modifierList = this.tempList.map((item, k) => ({ ...item, no: k }))
    },
    moved(e) {
      this.tempList = e.relatedContext.list
    }
  }
}
</script>

<style></style>
